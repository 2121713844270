import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import heroImage from '../../src/images/photo-1499159058454-75067059248a.avif';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Work from '@mui/icons-material/Work';


const Contact = () => {
    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50vh',
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: { xs: 'scroll', md: 'fixed' },
                    textAlign: 'center',
                    color: 'white',
                    padding: 4
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to bottom, rgb(1 87 104 / 31%), rgb(0 0 0 / 42%))',
                        zIndex: 1
                    }}
                />
            </Box>

            <Box sx={{
                padding: '4rem', display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* Main Heading */}
                <Box sx={{ width: {xs: '100%', md:'800px'} }}>
                    <Typography
                        variant="h3"
                        sx={{ fontWeight: 'bold', marginBottom: '1rem', fontFamily: 'Roboto, sans-serif' }}
                    >
                        Contact Us
                    </Typography>

                    {/* Subheading */}
                    <Typography
                        variant="body1"
                        sx={{ marginBottom: '3rem', color: 'gray', fontSize: '1.1rem' }}
                    >
                        We'd love to hear from you! Whether you have questions, need more information about our services, or are ready to start a project, feel free to reach out to us.
                    </Typography>
                </Box>

                {/* Icons and Contact Details */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '3rem',
                        flexWrap: 'wrap',
                    }}
                >
                    {/* Email */}
                    <Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#e0e0e0',
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                marginBottom: '0.5rem',
                                '&:hover': {
                                    backgroundColor: '#d0d0d0',
                                },
                            }}
                        >
                            <EmailIcon sx={{ fontSize: '2.5rem', color: '#000' }} />
                        </IconButton>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                            EMAIL
                        </Typography>
                        <Typography variant="body2">
                            <Link href="mailto:hr@kdmsystems.net" underline="hover">
                                hr@kdmsystems.net
                            </Link>
                        </Typography>
                    </Box>

                    {/* Phone */}
                    <Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#e0e0e0',
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                marginBottom: '0.5rem',
                                '&:hover': {
                                    backgroundColor: '#d0d0d0',
                                },
                            }}
                        >
                            <PhoneIcon sx={{ fontSize: '2.5rem', color: '#000' }} />
                        </IconButton>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                            PHONE
                        </Typography>
                        <Typography variant="body2">
                            +1 (718) 310-8612
                        </Typography>
                    </Box>

                    {/* address */}
                    <Box>
                        <IconButton
                            sx={{
                                backgroundColor: '#e0e0e0',
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                marginBottom: '0.5rem',
                                '&:hover': {
                                    backgroundColor: '#d0d0d0',
                                },
                            }}
                        >
                            <Work sx={{ fontSize: '2.5rem', color: '#000' }} />
                        </IconButton>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                            Address
                        </Typography>
                        <Typography variant="body2">
                            2628 Camomile DR,
                            <br />
                            Orlando, FL , 32837
                        </Typography>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};


export default Contact;

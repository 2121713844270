import * as React from 'react';
import {
    AppBar, Box, Toolbar, IconButton, Button,
    Menu, MenuItem, Typography, Container
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Home } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.jpeg';

const pages = [
    { name: 'About Us', path: '/about-us' },
    { name: 'Contact', path: '/contact' },
];

function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" style={{ backgroundColor: '#015768' }}>
            <Container maxWidth="auto">
                <Toolbar disableGutters>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        component={Link}
                                        to={page.path}
                                        sx={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        {page.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>


                    <IconButton edge="start" aria-label="logo" sx={{ mr: 2 }} component={Link} to='/'>
                        <img src={logo} alt="company logo" style={{ height: '50px' }}/>
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton
                        sx={{ mb:"2px"}}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        component={Link}
                        to="/"
                        color="inherit"
                    >
                        <Home />
                    </IconButton>

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button key={page.name} color="inherit" component={Link} to={page.path}>
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;

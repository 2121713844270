import React from 'react';
import {
    Box, Typography, Button, Container, Divider
} from '@mui/material';
import { Link } from 'react-router-dom';
import heroImage from '../../src/images/Cyberlink.jpg';
import service1 from '../../src/images/pngkey.com-cloud-computing-png-1174632.png';
import service2 from '../../src/images/Software integration-rafiki.png';
import service3 from '../../src/images/web-developer-illustration-2048x1490-saofs07x.png';
import service4 from '../../src/images/png.png';

const services = [
    {
        title: "Cloud Solutions",
        description: "Unlock the full potential of your business with our comprehensive Cloud Services. We help you transition to the cloud, providing scalable, secure, and cost-effective solutions. Whether it's public, private, or hybrid cloud, we ensure smooth migration and management, minimizing downtime and maximizing efficiency.",
        image: service1,
        align: 'left'
    },
    {
        title: "Integration Services",
        description: "Seamlessly connect your systems and applications with our expert Integration Services. Our solutions enhance your organization's agility and improve operational efficiency, allowing various platforms to work together in harmony.",
        image: service2,
        align: 'right'
    },
    {
        title: "Web Development",
        description: "Build dynamic, engaging, and user-friendly websites with our Web Development services. We focus on creating responsive, scalable, and visually appealing sites that deliver seamless user experiences.",
        image: service3,
        align: 'left'
    },
    {
        title: "Digital Transformation",
        description: "Transform your business processes with cutting-edge digital solutions. Our Digital Transformation services help you adopt new technologies that improve efficiency, customer experience, and drive innovation.",
        image: service4,
        align: 'right'
    },
];

const Home = () => {
    return (
        <Box>
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60vh',
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: { xs: 'scroll', md: 'fixed' },
                    textAlign: 'center',
                    color: 'white',
                    padding: 4
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to bottom, rgb(1 87 104 / 31%), rgb(0 0 0 / 42%))',
                        zIndex: 1
                    }}
                />
            </Box>

            {/* Overlapping Box with Welcome Text */}
            <Box
                sx={{
                    position: 'relative',
                    top: { xs: '-90px', md: '-150px', lg: '-90px' },
                    width: { xs: '90%', sm: '80%', md: '70%' },
                    margin: '0 auto',
                    paddingY: { xs: 1, md: 4 },
                    background: 'rgb(0 94 102 / 41%)',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    borderRadius: '16px',
                    zIndex: 2
                }}
            >
                <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
                    <Typography
                        variant="h1"
                        component="h1"
                        color='#eed1c3'
                        gutterBottom
                        sx={{ fontWeight: 'bold', 
                            fontSize: { xs: '2rem', md: '3rem' }
                         }}
                    >
                        Innovative Tech for Business Growth
                    </Typography>

                    <Typography
                        variant="h5"
                        component="p"
                        sx={{ 
                            marginBottom: 4, 
                            fontSize: { xs: '1rem', md: '1.5rem' },
                            fontFamily: 'fangsong'
                        }}
                    >
                        From software development to cloud solutions, we craft bespoke technologies that drive innovation and enable success in an evolving world.
                    </Typography>
                </Container>
            </Box>

            {/* We Are Section */}
            <Box
                sx={{
                    paddingBottom: { xs: 6, md: 10 },
                    textAlign: 'left'
                }}
            >
                <Container >
                    {/* Small Header - "We Are" */}
                    <Typography
                        variant="overline"
                        sx={{
                            letterSpacing: '2.5px',
                            color: '#015768',
                            fontWeight: 'bold',
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            marginBottom: 1,
                        }}
                    >
                        WE ARE
                    </Typography>

                    {/* Blue Divider Line */}
                    <Divider
                        sx={{
                            width: '80px',
                            marginTop: 0,
                            marginBottom: 3,
                            borderBottomWidth: '2px',
                            borderColor: '#015768',
                        }}
                    />

                    {/* Main Bold Statement - Headline */}
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '2.2rem', md: '3.5rem' },
                            lineHeight: 1.2,
                            marginBottom: 2,
                            marginLeft: { xs: '0px', md: '80px' },
                        }}
                    >
                        Your Partners in <br /> Digital Transformation
                    </Typography>

                    {/* Subheading - Description */}
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#757575',
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            marginLeft: { xs: '0px', md: '300px' },
                        }}
                    >
                        With a relentless focus on solving complex challenges, we create cutting-edge digital solutions that empower businesses to grow, adapt, and succeed in a rapidly changing world.
                    </Typography>
                </Container>
            </Box>

            {/* Services Section */}
            <Box
                sx={{
                    backgroundColor: '#f4f4f4',
                    paddingY: { xs: 6 },
                }}
            >
                <Container maxWidth="lg">
                    {services.map((service, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: service.align === 'left' ? { xs: 'center', md: 'flex-start' } : { xs: 'center', md: 'flex-end' },
                                alignItems: "center",
                                marginBottom: { xs: 8, md: 2 },
                                textAlign: service.align === 'left' ? {xs: 'center', md: 'left'} : {xs:'center', md: 'right'},
                            }}
                        >
                            {/* Icon */}
                            <Box
                                component="img"
                                src={service.image}
                                alt={service.title}
                                sx={{
                                    width: 260, height: 260,
                                    display: service.align === 'left' ? { xs: 'block', md: 'block' } : { xs: 'block', md: 'none' },
                                    marginX: '18px'
                                }}
                            />
                            {/* Text Content */}
                            <Box sx={{ maxWidth: '800px', padding: '16px' }}>
                                {/* Title */}
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '1.8rem', md: '2.4rem' },
                                        marginBottom: 2,
                                    }}
                                >
                                    {service.title}
                                </Typography>

                                {/* Description */}
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#757575',
                                        fontSize: { xs: '1rem', md: '1.25rem' }
                                    }}
                                >
                                    {service.description}
                                </Typography>
                            </Box>
                            <Box
                                component="img"
                                src={service.image}
                                alt={service.title}
                                sx={{
                                    margin: '12px',
                                    width: 260, height: 260,
                                    display: service.align === 'right' ? { xs: 'none', md: 'block' } : 'none'
                                }}
                            />
                        </Box>
                    ))}
                </Container>
            </Box>

            {/* Contact Section */}
            <Box
                sx={{
                    backgroundColor: '#015768',
                    color: 'white',
                    paddingY: 6,
                    textAlign: 'center'
                }}
            >
                <Container maxWidth="md">
                    <Typography variant="h4" component="h2" gutterBottom>
                        Ready to Get Started?
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ marginBottom: 4 }}>
                        Contact us today to see how we can help your business grow.
                    </Typography>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/contact"
                        sx={{ backgroundColor: '#eae6e3', color: '#015768' }}
                    >
                        Contact Us
                    </Button>
                </Container>
            </Box>
        </Box>
    );
};

export default Home;
